import React, { useEffect, useState } from "react"
import useOAuth2 from "../services/oauth2";
import config from "../services/config"
import { CognitoJwtVerifier } from "aws-jwt-verify";
import * as auth from "../services/auth"
import Message from "../components/common/message"


const OAuth2 = () => {
  const [error, setError] = useState("");
  const [userType, setUserType] = useState("Regular");
  const {data, uiInfo, getAuth} = useOAuth2({
    authorizeUrl: config.aws.auth_domain+"/oauth2/authorize",
    clientId: config.aws.client_id,
    redirectUri: config.base_url+"/oauth-callback/",
    scope: "aws.cognito.signin.user.admin+openid+profile",
    identity_provider: "COGNITO",
  });
  
  useEffect( ()=>{
    async function fetchData(){
      if(data === null){
        return
      }
      try {
        const payload = await extractDataFromKey();
        const cognitoUser = auth.getCognitoUser(payload['username']);
        let usersessionData = cognitoUser.getCognitoUserSession({
          IdToken: data.id_token, 
          AccessToken: data.access_token, 
          RefreshToken: data.refresh_token})
        cognitoUser.setSignInUserSession(usersessionData);
        
        cognitoUser.getUserAttributes(auth.parseUserAttributes)//why the fuck does this not work immidietly???
        auth.setUserGroups(payload['cognito:groups'])
        auth.setuserAccessToken(data.access_token)
        auth.redirectedToDefault()
      } catch {
        setError("Token provided by the provider not valid!");
      }
    }
    fetchData()
  }, [data])

  useEffect(()=>{
    let currUserType = null;
    if(typeof window != 'undefined'){
      currUserType = window.localStorage.getItem("userType")
    }
		if(!currUserType){
      setUserType("Regular")
    }
    setUserType(currUserType)
  }, [userType])



  const extractDataFromKey = async () =>{
    const verifier = CognitoJwtVerifier.create({
      userPoolId: config.aws.user_pool_id,
      tokenUse: "access",
      clientId: config.aws.client_id,
    });
    return await verifier.verify(
      data.access_token
    );
  }

  const changeUserType = function(selectedUserType){
    if(typeof window != 'undefined'){
      setUserType(selectedUserType)
      window.localStorage.setItem("userType", selectedUserType)
    }
  }

  return (
    <>
      {error ?? <Message type="error" message={error} />}
      <div className="selectdiv">
        <label>
          <select onChange={(e) => changeUserType(e.target.value)} value={userType}>
            <option value="Regular">Regular user</option>
            <option value="NDD only">NDD only user</option>
          </select>
        </label>
      </div>
      <br/>
      <input type="submit" className="button primary" value="Log in with OneWelcome" onClick={() => getAuth()}/>
    </>
  )
}

export default OAuth2
import config from "./config"
import React, { useCallback, useState, useRef } from 'react'


// whole implementation is mostly borrowed from https://tasoskakour.com/blog/react-use-oauth2

const OAUTH_STATE_KEY = 'react-use-oauth2-state-key';
const POPUP_HEIGHT = 450;
const POPUP_WIDTH = 500;
const OAUTH_RESPONSE = 'react-use-oauth2-response';

// https://medium.com/@dazcyril/generating-cryptographic-random-state-in-javascript-in-the-browser-c538b3daae50
const generateState = () => {
  const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let array = new Uint8Array(40);
  window.crypto.getRandomValues(array);
  array = array.map((x) => validChars.codePointAt(x % validChars.length));
  const randomState = String.fromCharCode.apply(null, array);
  return randomState;
};

const saveState = (state) => {
  sessionStorage.setItem(OAUTH_STATE_KEY, state);
};

const removeState = () => {
  sessionStorage.removeItem(OAUTH_STATE_KEY);
};

const openPopup = (url) => {
	// To fix issues with window.screen in multi-monitor setups, the easier option is to
	// center the pop-up over the parent window.
	const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
	const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
	return window.open(
		url,
		'OAuth2 Popup',
		`height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`
	);
};

const closePopup = (popupRef) => {
	popupRef.current?.close();
};

const cleanup = (
	intervalRef,
	popupRef,
	handleMessageListener
) => {
	clearInterval(intervalRef.current);
	closePopup(popupRef);
	removeState();
	window.removeEventListener('message', handleMessageListener);
};

const enhanceAuthorizeUrl = (
	authorizeUrl,
	clientId,
	redirectUri,
	scope,
	state,
  identity_provider
) => {
  return `${authorizeUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&identity_provider=${identity_provider}`;
};


// const objectToQuery = (object) => {
// 	return new URLSearchParams(object).toString();
// };

// const formatExchangeCodeForTokenServerURL = (
// 	serverUrl,
// 	clientId,
// 	code,
// 	redirectUri
// ) => {
// 	return `${serverUrl}?${objectToQuery({
// 		client_id: clientId,
// 		code,
// 		redirect_uri: redirectUri,
// 	})}`;
// };


function useOAuth2 (props) {
  const {
		authorizeUrl, 
    clientId, 
    redirectUri, 
    scope = '',
    identity_provider,
	} = props;

  const popupRef = useRef();
  const intervalRef = useRef();
  const [uiInfo, setUI] = useState({ loading: false, error: null });
  const [data, setData] = useState(null);


  const getAuth = useCallback(() => {
    // 1. Init
    setUI({
      loading: true,
      error: null,
    });

    // 2. Generate and save state
    const state = generateState();
    saveState(state);

    // 3. Open popup
    popupRef.current = openPopup(
      enhanceAuthorizeUrl(authorizeUrl, clientId, redirectUri, scope, state, identity_provider)
    );


    // 4. Register message listener
    async function handleMessageListener(message) {
      const type = message && message.data && message.data.type;
      try {
        if (type === OAUTH_RESPONSE) {
          const errorMaybe = message && message.data && message.data.error;
          if (errorMaybe) {
            setUI({
              loading: false,
              error: errorMaybe || 'Unknown Error',
            });
          } else {
            const code = message && message.data && message.data.payload && message.data.payload.code;
            const sendData = {client_id : clientId,
              code : code,
              redirect_uri: redirectUri}
            const response = await fetch( config.rest.oauth2_token_exchange,
              {method: 'POST',
                body: JSON.stringify(sendData)});
            if (response.response && response.response.error) {
              setUI({
                loading: false,
                error: "Failed to exchange code for token",
              });
            } else {
              let payload = await response.json();
              setUI({
                loading: false,
                error: null,
              });
              setData(payload.response);
            }
          }
        }
      } catch (genericError) {
        console.error(genericError);
        setUI({
          loading: false,
          error: genericError.toString(),
        });
      } finally {
        // Clear stuff ...
        if (type === OAUTH_RESPONSE) {
          cleanup(intervalRef, popupRef, handleMessageListener);
        }
      }
    }
    window.addEventListener('message', handleMessageListener);

    // 4. Begin interval to check if popup was closed forcefully by the user
    intervalRef.current = setInterval(() => {
      const popupClosed = !popupRef.current || !popupRef.current.window || popupRef.current.window.closed;
      if (popupClosed) {
        // Popup was closed before completing auth...
        setUI((ui) => ({
          ...ui,
          loading: false,
        }));
        console.warn('Warning: Popup was closed before completing authentication.');
        clearInterval(intervalRef.current);
        removeState();
        window.removeEventListener('message', handleMessageListener);
      }
    }, 250);

    // Remove listener(s) on unmount
    return () => {
      window.removeEventListener('message', handleMessageListener);
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [
		authorizeUrl,
		clientId,
		redirectUri,
		scope,
		setUI,
	])
  return {data, uiInfo, getAuth}
}

export default useOAuth2;
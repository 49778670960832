import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn, redirectedToDefault } from "../services/auth"
import LayoutLogin from "../components/layout_login"
import OAuth2 from "../components/oauth2"

class Login extends React.Component {

  constructor() {
		super();
		this.state = {
			message: ""
		}
	}
   

	render() {

		if(isLoggedIn() && typeof window != 'undefined') {
			redirectedToDefault()
		}



		return (
			<LayoutLogin>

        <OAuth2/>
        
			</LayoutLogin>
		)
	}
}

export default Login;
import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import Menu from "./navigation/menu"

const HeaderLogin = ({ siteTitle }) => {
  const items = {
    '/': 'Login',
  }
  return (<header className="main-login">
    <div className="logos">
    <StaticImage src="../images/postnl-logo.png"
      width={60}
      quality={80}
      formats={["auto", "webp", "avif"]}
      alt="PostNL logo" />

    <StaticImage src="../images/ews-logo.png"
      width={100}
      height={50}
      quality={80}
      formats={["auto", "webp", "avif"]}
      alt="Early Warning System (EWS) logo" />
  </div>
  
      <Menu items={ items } className="main-nav" />
  </header>)
}

HeaderLogin.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderLogin.defaultProps = {
  siteTitle: ``,
}

export default HeaderLogin

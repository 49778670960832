/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import PropTypes from "prop-types"
 
 import HeaderLogin from "./header_login"
 import "./layout_login.scss"
 
 const LayoutLogin = ({ children }) => {
 
	return (
	  <>
		 <HeaderLogin siteTitle={`PostNL EWS`} />
		 <div className="box">
			<main>{children}</main>
		</div>
	  </>
	)
 }
 
 LayoutLogin.propTypes = {
	children: PropTypes.node.isRequired
 }
 
 export default LayoutLogin
 
import React, {useState} from "react"
import PropTypes from "prop-types"
import "./message.scss"

class Message extends React.Component {
	state = {
		close: false
	};

	render() {
		if(this.props.message === "" || this.state.close) {
			return "";
		} else {
			return this.renderMessage();
		}
	}

	renderMessage() {
		return (
			<>
			<div className={"message " + this.props.type} onClick={(e) => {this.setState({close : true})} }>
				<span>{ this.props.message }</span>
				<a className="close" href="#">&times;</a>
			</div>
			</>
		);
	}
}

export default Message
